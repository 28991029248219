import "./Submission.css";
import moment from "moment";
import React, { ChangeEvent, Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { Box, Button, FormControl, Link, StyledEngineProvider, TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { push } from "redux-first-history";
import { findApplicationStageById } from "../../api/applicationStage";
import ctfLogo from "../../images/CTF-logo.png";
import vpukLogo from "../../images/vpuk.png";
import { ApplicationStage } from "../../interfaces/applicationStage";
import {
  Description,
  DropdownQuestion,
  MultipleAnswerSet,
  MultipleChoiceQuestion,
  MultipleLangAns,
  Section,
  SingleChoiceQuestion,
  YesNoQuestion,
} from "../../interfaces/createApplicationForm";
import { FieldEnum, FormContentElemEnum } from "../../models/fieldsEnum";
import { LanguageEnum } from "../../models/languageEnum";
import { RoundTextArea, RoundTextField } from "../utils/CustomStyle";
import Dropdown from "./DropDown";
import MultipleAnswerSetSubmission from "./MultipleAnswerSetSubmission";
import MultipleCheckBox from "./MultipleCheckBox";
import PhoneNumber from "./PhoneNumber";
import SelectYesNo from "./SelectYesNo";
import SingleCheckBox from "./SingleCheckBox";
import SingleChoice from "./SingleChoice";
import UploadFile from "./UploadFile";
import UploadImage from "./UploadImage";
import { createSubmissionAttachment, editSubmissionById, findSubmissionAttachmentsById, findSubmissionById } from "../../api/submission";
import { SubmissionOuterJoinApplicationStageUser } from "../../interfaces/submission";
import { SubmissionAttachment } from "../../interfaces/submissionAttachment";
import { useAppDispatch } from "../../redux/hooks";
import QuestionComponent from "./QuestionComponent";
import { ReactComponent as LongArrowLeftIcon } from "./LongArrowLeft.svg";
import { ReactComponent as LongArrowRightIcon } from "./LongArrowRight.svg";
import PhoneNumberV2 from "./PhoneNumberV2";

export interface SingleQuestion {
  id: string;
  fieldType: string;
  mandatory: boolean;
  fieldQuestion: MultipleLangAns;
  yesNoQuestions: YesNoQuestion;
  singleChoiceQuestions: SingleChoiceQuestion[];
  multipleChoiceQuestions: MultipleChoiceQuestion[];
  dropdownQuestions: DropdownQuestion;
  checkboxQuestions?: MultipleLangAns;
  multipleAnswerSet: MultipleAnswerSet;
  multipleAnswers?: boolean;
  numberOfMultipleAns?: number;
  description?: MultipleLangAns;
  hyperlink?: string;
  relationship: string;
}

interface FieldContent {
  contentElem: FormContentElemEnum;
  description: Description;
  section: Section;
  sectionQuestion: SingleQuestion[];
  singleQuestion: SingleQuestion;
  page: number;
}

export interface SubmissionFormInput {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

function EditSubmissionForm() {
  const lastSchool = sessionStorage.getItem("lastSchool");

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const required = t("view.pleaseAnswerTheQuestion");
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disableFiles, setDisableFiles] = useState<number[]>([]);

  const {
    register,
    formState: { errors },
    control,
    watch,
    handleSubmit,
    resetField,
    setError,
    clearErrors,
    setValue,
    getValues,
    trigger,
  } = useForm();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => console.log(value, name, type));
    return () => subscription.unsubscribe();
  }, [watch]);

  const [steps, setSteps] = useState<number[]>([1]);
  const [activeStep, setActiveStep] = useState(0);

  const handleStep = (step: number) => () => {
    if (Object.keys(errors).length === 0 && errors.constructor === Object) {
      setActiveStep(step);
    }
    window.scrollTo(0, 0);
  };

  const [applicationFormData, setApplicationFormData] = useState<ApplicationStage>();
  const [fieldContentData, setFieldContent] = useState<FieldContent[]>([]);
  const [submissionSavedData, setSubmissionSavedData] = useState<SubmissionOuterJoinApplicationStageUser>();
  const [submissionAttachments, setSubmissionAttachments] = useState<SubmissionAttachment[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const timeoutRef = useRef<number>();

  async function loadData(applicationListId: number, stageId: number, submissionId: number) {
    const result = await findApplicationStageById(applicationListId, stageId);

    if (result.success) {
      setApplicationFormData(result.data);

      let pages = 0;
      const fieldContents = result.data.fieldContent;
      for (const fieldContent of fieldContents) {
        if (fieldContent.contentElem === FormContentElemEnum.NEW_PAGE) {
          pages += 1;
        } else {
          fieldContent.page = pages;
        }
      }
      setFieldContent(fieldContents);

      const stepsArray = [];
      for (let i = 0; i <= pages; i++) {
        stepsArray.push(i + 1);
      }
      setSteps(stepsArray);
    }

    const submission = await findSubmissionById(applicationListId, stageId, submissionId);
    setSubmissionSavedData(submission.data);

    const submissionAttachment = await findSubmissionAttachmentsById(applicationListId, stageId, submissionId);
    setSubmissionAttachments(submissionAttachment.data);
  }

  const { applicationListId, stageId, submissionId } = params;

  useEffect(() => {
    const fetching = async () => {
      if (applicationListId && stageId && submissionId) {
        await loadData(parseInt(applicationListId), parseInt(stageId), parseInt(submissionId));
        setIsLoading(false);
      }
    };
    fetching();
  }, [params]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const onSubmit: SubmitHandler<SubmissionFormInput> = async (data) => {
    setIsSubmitting(true);
    timeoutRef.current = window.setTimeout(() => {
      setIsSubmitting(false);
    }, 10000);
    const formData = new FormData();
    const fileFields = [];

    for (const field in data) {
      if (data[field] instanceof FileList) {
        fileFields.push(field);

        for (let i = 0; i < data[field].length; i++) {
          formData.append(field, data[field][i]);
        }
      }
    }

    const formattedData = {
      fields: data,
      deselectedFiles: disableFiles,
    };

    if (applicationListId && stageId && submissionId) {
      const result = await editSubmissionById(parseInt(applicationListId), parseInt(stageId), parseInt(submissionId), formattedData);
      const attachment = await createSubmissionAttachment(parseInt(applicationListId), parseInt(stageId), parseInt(submissionId), formData);
      if (result.success && attachment.success) {
        dispatch(push(`/cms/school/${lastSchool}/application_list/${parseInt(applicationListId)}/dashboard/${parseInt(stageId)}`));
      }
    }
  };

  useEffect(() => {
    if (submissionSavedData) {
      const { fields } = submissionSavedData;
      for (const field in fields) {
        if (Object.prototype.hasOwnProperty.call(fields, field)) {
          setValue(field, fields[field]);
        }
      }
    }
  }, [submissionSavedData]);

  const [linkedQuestions, setLinkedQuestions] = useState<number[]>([]);
  useEffect(() => {
    if (!isLoading) {
      const linkedQuestionsArr: number[] = [];
      for (const [index, fieldContent] of fieldContentData.entries()) {
        if (fieldContent.contentElem === FormContentElemEnum.QUESTION) {
          linkedQuestionsArr.push(index);
        }
      }

      for (const fieldContent of fieldContentData) {
        if (fieldContent.contentElem === FormContentElemEnum.QUESTION) {
          const yesLinkedQuestions = fieldContent.singleQuestion.yesNoQuestions.yes.linkedQuestion;
          const noLinkedQuestions = fieldContent.singleQuestion.yesNoQuestions.no.linkedQuestion;
          for (const yesLinkedQuestion of yesLinkedQuestions) {
            const index = linkedQuestionsArr.indexOf(parseInt(yesLinkedQuestion));
            linkedQuestionsArr.splice(index, 1);
          }

          for (const noLinkedQuestion of noLinkedQuestions) {
            const index = linkedQuestionsArr.indexOf(parseInt(noLinkedQuestion));
            linkedQuestionsArr.splice(index, 1);
          }

          for (const singleChoice of fieldContent.singleQuestion.singleChoiceQuestions) {
            const { others, linkedQuestion: singleChoiceLinkedQuestions } = singleChoice;

            for (const singleChoiceLinkedQuestion of singleChoiceLinkedQuestions) {
              const questionId = fieldContentData[parseInt(singleChoiceLinkedQuestion)].singleQuestion.id;
              if (submissionSavedData) {
                const { fields } = submissionSavedData;

                if (fields[questionId] === undefined || (others && fields[questionId] !== "others")) {
                  const index = linkedQuestionsArr.indexOf(parseInt(singleChoiceLinkedQuestion));
                  linkedQuestionsArr.splice(index, 1);
                }
              } else {
                const index = linkedQuestionsArr.indexOf(parseInt(singleChoiceLinkedQuestion));
                linkedQuestionsArr.splice(index, 1);
              }
            }
          }
        }
      }
      setLinkedQuestions(linkedQuestionsArr);
    }
  }, [fieldContentData, isLoading]);

  const [linkedSessionQuestions, setLinkedSessionQuestions] = useState<number[]>([]);
  useEffect(() => {
    if (!isLoading) {
      const linkedSessionQuestionsArr: number[] = [];
      for (const fieldContent of fieldContentData) {
        if (fieldContent.contentElem === FormContentElemEnum.SECTION) {
          for (const [index] of fieldContent.sectionQuestion.entries()) {
            linkedSessionQuestionsArr.push(index);
          }

          for (const sectionQuestion of fieldContent.sectionQuestion) {
            const yesLinkedQuestions = sectionQuestion.yesNoQuestions.yes.linkedQuestion;
            const noLinkedQuestions = sectionQuestion.yesNoQuestions.no.linkedQuestion;
            for (const yesLinkedQuestion of yesLinkedQuestions) {
              const index = linkedSessionQuestionsArr.indexOf(parseInt(yesLinkedQuestion));
              linkedSessionQuestionsArr.splice(index, 1);
            }
            for (const noLinkedQuestion of noLinkedQuestions) {
              const index = linkedSessionQuestionsArr.indexOf(parseInt(noLinkedQuestion));
              linkedSessionQuestionsArr.splice(index, 1);
            }

            for (const singleChoice of sectionQuestion.singleChoiceQuestions) {
              const { others, linkedQuestion: singleChoiceLinkedQuestions } = singleChoice;

              for (const singleChoiceLinkedQuestion of singleChoiceLinkedQuestions) {
                const questionId = sectionQuestion.id;
                if (submissionSavedData) {
                  const { fields } = submissionSavedData;
                  if (fields[questionId] === undefined || (others && fields[questionId] !== "others")) {
                    const index = linkedSessionQuestionsArr.indexOf(parseInt(singleChoiceLinkedQuestion));
                    linkedSessionQuestionsArr.splice(index, 1);
                  }
                } else {
                  const index = linkedSessionQuestionsArr.indexOf(parseInt(singleChoiceLinkedQuestion));
                  linkedSessionQuestionsArr.splice(index, 1);
                }
              }
            }
          }
        }
      }
      setLinkedSessionQuestions(linkedSessionQuestionsArr);
    }
  }, [fieldContentData, isLoading]);

  const getMultipleAnswerNumber = (answers: number) => {
    const answerArray = [];
    for (let i = 0; i < answers; i++) {
      answerArray.push(i);
    }
    return answerArray;
  };

  return (
    <>
      {isLoading && <Grid sx={{ m: 3 }}>Loading...</Grid>}
      {!isLoading && fieldContentData.length === 0 && <Grid sx={{ m: 3 }}>No data on submission form id = {params.stageId}...</Grid>}

      {!isLoading && fieldContentData.length !== 0 && submissionSavedData && (
        <StyledEngineProvider injectFirst>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" method="post">
            <Grid container sx={{ m: 3 }} display="flex" direction="column" alignItems="center" justifyContent="center">
              <Grid xs={12}>
                {lastSchool === "vpp" && (
                  <Grid xs={12} display="flex" justifyContent="center" sx={{ m: 5 }}>
                    <img src={ctfLogo} alt="School Logo" width={300} height={86} />
                  </Grid>
                )}
                {lastSchool === "vpuk" && (
                  <Grid xs={12} display="flex" justifyContent="center" sx={{ mt: 5, mb: 5 }}>
                    <img src={vpukLogo} alt="School Logo" width={300} height={86} />
                  </Grid>
                )}

                <Grid xs={12} sx={{ mb: 2.5 }} display="flex" justifyContent="center" className="submissionTitle">
                  {i18n.resolvedLanguage === LanguageEnum.ZH_HK && applicationFormData !== undefined
                    ? `${applicationFormData.titleZhHk}`
                    : i18n.resolvedLanguage === LanguageEnum.EN_US && applicationFormData !== undefined
                    ? `${applicationFormData.titleEnUs}`
                    : ""}
                </Grid>
              </Grid>

              <Grid xs={12} sx={{ mb: 5, maxWidth: 1440 }}>
                {fieldContentData.map((questionField, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={index} className={activeStep === questionField.page ? "submissionFormActiveStep" : "submissionFormInactiveStep"}>
                      {questionField.contentElem === FormContentElemEnum.DESCRIPTION && (
                        <Box sx={{ mb: fieldContentData[index + 1]?.contentElem === FormContentElemEnum.NEW_PAGE ? 0 : 2.5 }}>
                          <Grid container>
                            {/* <Grid xs={3}></Grid> */}
                            <Grid xs={12}>
                              <div className="submissionDescription">
                                {i18n.resolvedLanguage === LanguageEnum.ZH_HK
                                  ? `${questionField.description.zhHK}`
                                  : i18n.resolvedLanguage === LanguageEnum.EN_US
                                  ? `${questionField.description.enUS}`
                                  : ""}
                              </div>
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <Link
                                className="link"
                                component="button"
                                onClick={() => {
                                  window.open(questionField.description.hyperlink);
                                }}
                              >
                                {questionField.description.hyperlink}
                              </Link>
                            </Grid>
                            {/* <Grid xs={3}></Grid> */}
                          </Grid>
                        </Box>
                      )}

                      {questionField.contentElem === FormContentElemEnum.SECTION && (
                        <Box>
                          <Grid
                            sx={{
                              mb: fieldContentData[index + 1]?.contentElem === FormContentElemEnum.NEW_PAGE ? 0 : 2.5,
                            }}
                          >
                            <Grid container>
                              {/* <Grid xs={3}></Grid> */}
                              <Grid xs={12}>
                                <div className="submissionsectionTitle">
                                  {i18n.resolvedLanguage === LanguageEnum.ZH_HK
                                    ? `${questionField.section.sectionTitle.zhHK}`
                                    : i18n.resolvedLanguage === LanguageEnum.EN_US
                                    ? `${questionField.section.sectionTitle.enUS}`
                                    : ""}
                                </div>
                              </Grid>
                              {/* <Grid xs={3}></Grid> */}
                            </Grid>
                          </Grid>
                          {questionField.section.sectionDescription !== undefined && (
                            <Grid sx={{ mb: 1.5 }}>
                              <Grid container>
                                {/* <Grid xs={3}></Grid> */}
                                <Grid xs={12} display="flex" direction="column" alignItems="center" justifyContent="center">
                                  <div className="submissionSessionDescription" style={{ maxWidth: 1440 }}>
                                    {i18n.resolvedLanguage === LanguageEnum.ZH_HK
                                      ? `${questionField.section.sectionDescription.zhHK}`
                                      : i18n.resolvedLanguage === LanguageEnum.EN_US
                                      ? `${questionField.section.sectionDescription.enUS}`
                                      : ""}
                                  </div>
                                </Grid>
                                {/* <Grid xs={3}></Grid> */}
                              </Grid>
                            </Grid>
                          )}

                          {questionField.sectionQuestion.map((question: SingleQuestion, sectionQuestionIndex: number) => {
                            return (
                              <Box key={question.id}>
                                {sectionQuestionIndex === linkedSessionQuestions.find((elem) => elem === sectionQuestionIndex) && (
                                  <Grid sx={{ my: 1.5 }}>
                                    <Grid container>
                                      {/* <Grid xs={3}></Grid> */}
                                      <Grid xs={12} display="flex" direction="column" alignItems="center" justifyContent="center">
                                        {/* <Box sx={{ maxWidth: 1440 }}> */}
                                        {question.fieldType === FieldEnum.YES_NO && (
                                          <SelectYesNo
                                            register={register}
                                            errors={errors}
                                            watch={watch}
                                            setValue={setValue}
                                            control={control}
                                            question={question}
                                            setLinkedQuestion={(selectedIndexes: number[], deselectIndexes: number[]) => {
                                              function checkExtra(originDeselectIndexes: number[], originalArr: number[]) {
                                                const newDeselectIndexes = originDeselectIndexes;

                                                function loopDeselectIndex(i: number) {
                                                  const singleChoices = questionField.sectionQuestion[i].singleChoiceQuestions;
                                                  const yesLinkedQuestions = questionField.sectionQuestion[i].yesNoQuestions.yes.linkedQuestion;
                                                  const noLinkedQuestions = questionField.sectionQuestion[i].yesNoQuestions.no.linkedQuestion;

                                                  for (const singleChoice of singleChoices) {
                                                    const singleChoiceLinkedQuestions = singleChoice.linkedQuestion;
                                                    for (const singleChoiceLinkedQuestion of singleChoiceLinkedQuestions) {
                                                      newDeselectIndexes.push(parseInt(singleChoiceLinkedQuestion));
                                                      loopDeselectIndex(parseInt(singleChoiceLinkedQuestion));
                                                    }
                                                  }

                                                  for (const yesLinkedQuestion of yesLinkedQuestions) {
                                                    newDeselectIndexes.push(parseInt(yesLinkedQuestion));
                                                    loopDeselectIndex(parseInt(yesLinkedQuestion));
                                                  }

                                                  for (const noLinkedQuestion of noLinkedQuestions) {
                                                    newDeselectIndexes.push(parseInt(noLinkedQuestion));
                                                    loopDeselectIndex(parseInt(noLinkedQuestion));
                                                  }
                                                }

                                                for (const deselectIndex of newDeselectIndexes) {
                                                  loopDeselectIndex(deselectIndex);
                                                  resetField(`${fieldContentData[0].sectionQuestion[deselectIndex].id}`);
                                                }

                                                const updatedArr = originalArr.filter((linkedQuestion) => !newDeselectIndexes.includes(linkedQuestion));
                                                return updatedArr;
                                              }

                                              let modifiedArr: number[] = [...linkedSessionQuestions];
                                              // console.log("Before REMOVING =", modifiedArr);

                                              const afterDeselectionArr = checkExtra(deselectIndexes, modifiedArr);
                                              modifiedArr = afterDeselectionArr;
                                              // console.log("After REMOVING =", afterDeselectionArr);

                                              const checkSelect = linkedSessionQuestions.filter((linkedSessionQuestion) => selectedIndexes.includes(linkedSessionQuestion));
                                              if (checkSelect.length === 0) {
                                                for (const selectedIndex of selectedIndexes) {
                                                  modifiedArr.push(selectedIndex);
                                                }
                                              }
                                              // console.log("After SELECTING =", modifiedArr);
                                              setLinkedSessionQuestions(modifiedArr);
                                            }}
                                          />
                                        )}

                                        {question.fieldType === FieldEnum.SHORT_ANSWER && (
                                          <FormControl fullWidth error={errors[question.id] && true}>
                                            <QuestionComponent
                                              fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                              fieldQuestionEnUs={question.fieldQuestion.enUS}
                                              mandatory={question.mandatory}
                                              descriptionZhHk={question.description?.zhHK}
                                              descriptionEnUs={question.description?.enUS}
                                              hyperlink={question.hyperlink}
                                              error={errors[question.id] && true}
                                            />
                                            {question.mandatory === true ? (
                                              <Controller
                                                name={`${question.id}` as const}
                                                control={control}
                                                rules={{ required: t("forms.required") }}
                                                defaultValue=""
                                                render={({ field }) => (
                                                  <RoundTextField
                                                    {...field}
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    placeholder=""
                                                    error={errors[question.id] && true}
                                                    helperText={errors[question.id] ? required : ""}
                                                  />
                                                )}
                                              />
                                            ) : (
                                              <Controller
                                                name={`${question.id}` as const}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                  <RoundTextField
                                                    {...field}
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    placeholder=""
                                                    error={errors[question.id] && true}
                                                    helperText={errors[question.id] ? required : ""}
                                                  />
                                                )}
                                              />
                                            )}

                                            {question.multipleAnswers !== undefined && question.multipleAnswers && (
                                              <div className="submissionAllowMultipleAnsContainer">
                                                <div className="submissionAllowMultipleAnsBtn">
                                                  <AddCircleOutlineRoundedIcon fontSize="small" />
                                                </div>
                                                <div className="submissionAllowMultipleAnsText">新增答案</div>
                                              </div>
                                            )}
                                          </FormControl>
                                        )}

                                        {question.fieldType === FieldEnum.PARAGRAPH && (
                                          <>
                                            {question.mandatory === true ? (
                                              <FormControl fullWidth error={errors[question.id] && true}>
                                                <QuestionComponent
                                                  fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                                  fieldQuestionEnUs={question.fieldQuestion.enUS}
                                                  mandatory={question.mandatory}
                                                  descriptionZhHk={question.description?.zhHK}
                                                  descriptionEnUs={question.description?.enUS}
                                                  hyperlink={question.hyperlink}
                                                  error={errors[question.id] && true}
                                                />
                                                <Controller
                                                  name={`${question.id}` as const}
                                                  control={control}
                                                  rules={{ required: t("forms.required") }}
                                                  defaultValue=""
                                                  render={({ field }) => (
                                                    <RoundTextArea
                                                      {...field}
                                                      fullWidth
                                                      size="small"
                                                      type="text"
                                                      placeholder=""
                                                      InputProps={{
                                                        inputComponent: TextareaAutosize,
                                                      }}
                                                      error={errors[question.id] && true}
                                                      helperText={errors[question.id] ? required : ""}
                                                    />
                                                  )}
                                                />
                                              </FormControl>
                                            ) : (
                                              <FormControl fullWidth error={errors[question.id] && true}>
                                                <QuestionComponent
                                                  fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                                  fieldQuestionEnUs={question.fieldQuestion.enUS}
                                                  mandatory={question.mandatory}
                                                  descriptionZhHk={question.description?.zhHK}
                                                  descriptionEnUs={question.description?.enUS}
                                                  hyperlink={question.hyperlink}
                                                  error={errors[question.id] && true}
                                                />
                                                <Controller
                                                  name={`${question.id}` as const}
                                                  control={control}
                                                  defaultValue=""
                                                  render={({ field }) => (
                                                    <RoundTextArea
                                                      {...field}
                                                      fullWidth
                                                      size="small"
                                                      type="text"
                                                      placeholder=""
                                                      InputProps={{
                                                        inputComponent: TextareaAutosize,
                                                      }}
                                                      error={errors[question.id] && true}
                                                      helperText={errors[question.id] ? required : ""}
                                                    />
                                                  )}
                                                />
                                              </FormControl>
                                            )}
                                          </>
                                        )}

                                        {question.fieldType === FieldEnum.VALUE && (
                                          <>
                                            {question.mandatory === true ? (
                                              <FormControl fullWidth error={errors[question.id] && true}>
                                                <QuestionComponent
                                                  fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                                  fieldQuestionEnUs={question.fieldQuestion.enUS}
                                                  mandatory={question.mandatory}
                                                  descriptionZhHk={question.description?.zhHK}
                                                  descriptionEnUs={question.description?.enUS}
                                                  hyperlink={question.hyperlink}
                                                  error={errors[question.id] && true}
                                                />
                                                <Controller
                                                  name={`${question.id}` as const}
                                                  control={control}
                                                  rules={{ required: t("forms.required") }}
                                                  defaultValue={"" as unknown as number}
                                                  render={({ field }) => (
                                                    <RoundTextField
                                                      {...field}
                                                      fullWidth
                                                      size="small"
                                                      type="number"
                                                      placeholder=""
                                                      onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        if (parseInt(e.target.value) >= 0) {
                                                          field.onChange(parseInt(e.target.value));
                                                        }
                                                      }}
                                                      error={errors[question.id] && true}
                                                      helperText={errors[question.id] ? required : ""}
                                                    />
                                                  )}
                                                />
                                              </FormControl>
                                            ) : (
                                              <FormControl fullWidth error={errors[question.id] && true}>
                                                <QuestionComponent
                                                  fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                                  fieldQuestionEnUs={question.fieldQuestion.enUS}
                                                  mandatory={question.mandatory}
                                                  descriptionZhHk={question.description?.zhHK}
                                                  descriptionEnUs={question.description?.enUS}
                                                  hyperlink={question.hyperlink}
                                                  error={errors[question.id] && true}
                                                />
                                                <Controller
                                                  name={`${question.id}` as const}
                                                  control={control}
                                                  defaultValue={"" as unknown as number}
                                                  render={({ field }) => (
                                                    <RoundTextField
                                                      {...field}
                                                      fullWidth
                                                      size="small"
                                                      type="number"
                                                      placeholder=""
                                                      onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        if (parseInt(e.target.value) >= 0) {
                                                          field.onChange(parseInt(e.target.value));
                                                        }
                                                      }}
                                                      error={errors[question.id] && true}
                                                      helperText={errors[question.id] ? required : ""}
                                                    />
                                                  )}
                                                />
                                              </FormControl>
                                            )}
                                          </>
                                        )}

                                        {question.fieldType === FieldEnum.IMAGE_UPLOAD && (
                                          <UploadImage
                                            question={question}
                                            submissionAttachments={submissionAttachments}
                                            deselectFiles={(id: number) => {
                                              const deletedFiles = disableFiles;
                                              deletedFiles.push(id);
                                              setDisableFiles(deletedFiles);
                                            }}
                                            register={register}
                                            errors={errors}
                                            watch={watch}
                                            clearErrors={clearErrors}
                                            setValue={setValue}
                                            getValues={getValues}
                                            control={control}
                                            trigger={trigger}
                                          />
                                        )}

                                        {question.fieldType === FieldEnum.FILE_UPLOAD && (
                                          <UploadFile
                                            question={question}
                                            submissionAttachments={submissionAttachments}
                                            deselectFiles={(id: number) => {
                                              const deletedFiles = disableFiles;
                                              deletedFiles.push(id);
                                              setDisableFiles(deletedFiles);
                                            }}
                                            register={register}
                                            errors={errors}
                                            watch={watch}
                                            setValue={setValue}
                                            getValues={getValues}
                                            control={control}
                                            trigger={trigger}
                                          />
                                        )}

                                        {question.fieldType === FieldEnum.SINGLE_CHOICE && (
                                          <SingleChoice
                                            register={register}
                                            errors={errors}
                                            watch={watch}
                                            setValue={setValue}
                                            control={control}
                                            question={question}
                                            setLinkedQuestion={(selectedIndexes: number[], deselectedIndexes: number[]) => {
                                              function checkExtra(originDeselectIndexes: number[], originalArr: number[]) {
                                                const newDeselectIndexes = originDeselectIndexes;

                                                function loopDeselectIndex(i: number) {
                                                  const singleChoices = questionField.sectionQuestion[i].singleChoiceQuestions;
                                                  const yesLinkedQuestions = questionField.sectionQuestion[i].yesNoQuestions.yes.linkedQuestion;
                                                  const noLinkedQuestions = questionField.sectionQuestion[i].yesNoQuestions.no.linkedQuestion;

                                                  for (const singleChoice of singleChoices) {
                                                    const singleChoiceLinkedQuestions = singleChoice.linkedQuestion;
                                                    for (const singleChoiceLinkedQuestion of singleChoiceLinkedQuestions) {
                                                      newDeselectIndexes.push(parseInt(singleChoiceLinkedQuestion));
                                                      loopDeselectIndex(parseInt(singleChoiceLinkedQuestion));
                                                    }
                                                  }

                                                  for (const yesLinkedQuestion of yesLinkedQuestions) {
                                                    newDeselectIndexes.push(parseInt(yesLinkedQuestion));
                                                    loopDeselectIndex(parseInt(yesLinkedQuestion));
                                                  }

                                                  for (const noLinkedQuestion of noLinkedQuestions) {
                                                    newDeselectIndexes.push(parseInt(noLinkedQuestion));
                                                    loopDeselectIndex(parseInt(noLinkedQuestion));
                                                  }
                                                }

                                                for (const deselectIndex of newDeselectIndexes) {
                                                  loopDeselectIndex(deselectIndex);
                                                  resetField(`${fieldContentData[index].sectionQuestion[deselectIndex].id}`);
                                                }

                                                const updatedArr = originalArr.filter((linkedQuestion) => !newDeselectIndexes.includes(linkedQuestion));
                                                return updatedArr;
                                              }

                                              let modifiedArr: number[] = [...linkedSessionQuestions];

                                              const afterDeselectionArr = checkExtra(deselectedIndexes, modifiedArr);
                                              modifiedArr = afterDeselectionArr;

                                              const checkSelect = linkedSessionQuestions.filter((linkedSessionQuestion) => selectedIndexes.includes(linkedSessionQuestion));
                                              if (checkSelect.length === 0) {
                                                for (const selectedIndex of selectedIndexes) {
                                                  modifiedArr.push(selectedIndex);
                                                }
                                              }
                                              setLinkedSessionQuestions(modifiedArr);
                                            }}
                                          />
                                        )}

                                        {question.fieldType === FieldEnum.MULTIPLE_CHOICES && (
                                          <MultipleCheckBox question={question} errors={errors} setValue={setValue} getValues={getValues} control={control} trigger={trigger} />
                                        )}

                                        {question.fieldType === FieldEnum.DROPDOWN && (
                                          <Dropdown
                                            register={register}
                                            errors={errors}
                                            control={control}
                                            question={question}
                                            dropdownQuestions={question.dropdownQuestions.type}
                                            dropdownQuestionsCustom={question.dropdownQuestions.custom}
                                          />
                                        )}

                                        {question.fieldType === FieldEnum.DATEPICKER && (
                                          <>
                                            {question.mandatory === true ? (
                                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <FormControl fullWidth error={errors[question.id] && true}>
                                                  <QuestionComponent
                                                    fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                                    fieldQuestionEnUs={question.fieldQuestion.enUS}
                                                    mandatory={question.mandatory}
                                                    descriptionZhHk={question.description?.zhHK}
                                                    descriptionEnUs={question.description?.enUS}
                                                    hyperlink={question.hyperlink}
                                                    error={errors[question.id] && true}
                                                  />
                                                  <Controller
                                                    name={`${question.id}` as const}
                                                    control={control}
                                                    defaultValue={null}
                                                    rules={{ required: t("forms.required") }}
                                                    render={({ field }) => (
                                                      <DesktopDatePicker
                                                        {...field}
                                                        inputFormat="DD/MM/yyyy"
                                                        onChange={(date) => {
                                                          field.onChange(moment(date).toDate());
                                                        }}
                                                        renderInput={(formParams) => (
                                                          <RoundTextField
                                                            {...formParams}
                                                            size="small"
                                                            fullWidth
                                                            type="date"
                                                            error={errors[question.id] && true}
                                                            helperText={errors[question.id] && required}
                                                          />
                                                        )}
                                                      />
                                                    )}
                                                  />
                                                </FormControl>
                                              </LocalizationProvider>
                                            ) : (
                                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <FormControl fullWidth error={errors[question.id] && true}>
                                                  <QuestionComponent
                                                    fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                                    fieldQuestionEnUs={question.fieldQuestion.enUS}
                                                    mandatory={question.mandatory}
                                                    descriptionZhHk={question.description?.zhHK}
                                                    descriptionEnUs={question.description?.enUS}
                                                    hyperlink={question.hyperlink}
                                                    error={errors[question.id] && true}
                                                  />
                                                  <Controller
                                                    name={`${question.id}` as const}
                                                    control={control}
                                                    defaultValue={null}
                                                    render={({ field }) => (
                                                      <DesktopDatePicker
                                                        {...field}
                                                        inputFormat="DD/MM/yyyy"
                                                        onChange={(date) => {
                                                          field.onChange(moment(date).toDate());
                                                        }}
                                                        renderInput={(formParams) => (
                                                          <RoundTextField
                                                            {...formParams}
                                                            size="small"
                                                            fullWidth
                                                            type="date"
                                                            error={errors[question.id] && true}
                                                            helperText={errors[question.id] && required}
                                                          />
                                                        )}
                                                      />
                                                    )}
                                                  />
                                                </FormControl>
                                              </LocalizationProvider>
                                            )}
                                          </>
                                        )}

                                        {question.fieldType === FieldEnum.EMAIL && (
                                          <>
                                            {question.mandatory === true ? (
                                              <FormControl fullWidth error={errors[question.id] && true}>
                                                <QuestionComponent
                                                  fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                                  fieldQuestionEnUs={question.fieldQuestion.enUS}
                                                  mandatory={question.mandatory}
                                                  descriptionZhHk={question.description?.zhHK}
                                                  descriptionEnUs={question.description?.enUS}
                                                  hyperlink={question.hyperlink}
                                                  error={errors[question.id] && true}
                                                />
                                                <Controller
                                                  name={`${question.id}` as const}
                                                  control={control}
                                                  rules={{ required: t("forms.required") }}
                                                  defaultValue=""
                                                  render={({ field }) => (
                                                    <RoundTextField
                                                      {...field}
                                                      fullWidth
                                                      size="small"
                                                      type="email"
                                                      placeholder=""
                                                      error={errors[question.id] && true}
                                                      helperText={errors[question.id] ? required : ""}
                                                    />
                                                  )}
                                                />
                                              </FormControl>
                                            ) : (
                                              <FormControl fullWidth error={errors[question.id] && true}>
                                                <QuestionComponent
                                                  fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                                  fieldQuestionEnUs={question.fieldQuestion.enUS}
                                                  mandatory={question.mandatory}
                                                  descriptionZhHk={question.description?.zhHK}
                                                  descriptionEnUs={question.description?.enUS}
                                                  hyperlink={question.hyperlink}
                                                  error={errors[question.id] && true}
                                                />
                                                <Controller
                                                  name={`${question.id}` as const}
                                                  control={control}
                                                  defaultValue=""
                                                  render={({ field }) => (
                                                    <RoundTextField
                                                      {...field}
                                                      fullWidth
                                                      size="small"
                                                      type="email"
                                                      placeholder=""
                                                      error={errors[question.id] && true}
                                                      helperText={errors[question.id] ? required : ""}
                                                    />
                                                  )}
                                                />
                                              </FormControl>
                                            )}
                                          </>
                                        )}

                                        {/* {question.fieldType === FieldEnum.PHONE_NUMBER && (
                                          <PhoneNumber
                                            register={register}
                                            errors={errors}
                                            watch={watch}
                                            setValue={setValue}
                                            getValues={getValues}
                                            control={control}
                                            question={question}
                                          />
                                        )} */}

                                        {question.fieldType === FieldEnum.PHONE_NUMBER && (
                                          <PhoneNumberV2
                                            register={register}
                                            errors={errors}
                                            watch={watch}
                                            setValue={setValue}
                                            getValues={getValues}
                                            control={control}
                                            question={question}
                                          />
                                        )}

                                        {question.fieldType === FieldEnum.APPLICATION_NUMBER && (
                                          <>
                                            {question.mandatory === true ? (
                                              <FormControl fullWidth error={errors[question.id] && true}>
                                                <QuestionComponent
                                                  fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                                  fieldQuestionEnUs={question.fieldQuestion.enUS}
                                                  mandatory={question.mandatory}
                                                  descriptionZhHk={question.description?.zhHK}
                                                  descriptionEnUs={question.description?.enUS}
                                                  hyperlink={question.hyperlink}
                                                  error={errors[question.id] && true}
                                                />
                                                <Controller
                                                  name={`${question.id}` as const}
                                                  control={control}
                                                  rules={{ required: t("forms.required") }}
                                                  defaultValue=""
                                                  render={({ field }) => (
                                                    <RoundTextField
                                                      {...field}
                                                      fullWidth
                                                      size="small"
                                                      type="text"
                                                      placeholder=""
                                                      error={errors[question.id] && true}
                                                      helperText={errors[question.id] ? required : ""}
                                                    />
                                                  )}
                                                />
                                              </FormControl>
                                            ) : (
                                              <FormControl fullWidth error={errors[question.id] && true}>
                                                <QuestionComponent
                                                  fieldQuestionZhHk={question.fieldQuestion.zhHK}
                                                  fieldQuestionEnUs={question.fieldQuestion.enUS}
                                                  mandatory={question.mandatory}
                                                  descriptionZhHk={question.description?.zhHK}
                                                  descriptionEnUs={question.description?.enUS}
                                                  hyperlink={question.hyperlink}
                                                  error={errors[question.id] && true}
                                                />
                                                <Controller
                                                  name={`${question.id}` as const}
                                                  control={control}
                                                  defaultValue=""
                                                  render={({ field }) => (
                                                    <RoundTextField
                                                      {...field}
                                                      fullWidth
                                                      size="small"
                                                      type="text"
                                                      placeholder=""
                                                      error={errors[question.id] && true}
                                                      helperText={errors[question.id] ? required : ""}
                                                    />
                                                  )}
                                                />
                                              </FormControl>
                                            )}
                                          </>
                                        )}

                                        {question.fieldType === FieldEnum.CHECKBOX && (
                                          <SingleCheckBox register={register} errors={errors} setValue={setValue} getValues={getValues} control={control} question={question} />
                                        )}

                                        {question.fieldType === FieldEnum.MULTIPLE_ANSWER_SET && (
                                          <MultipleAnswerSetSubmission
                                            question={question}
                                            answerSets={question.multipleAnswerSet.answerSets}
                                            answers={question.multipleAnswerSet.answers}
                                            register={register}
                                            errors={errors}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            watch={watch}
                                            setValue={setValue}
                                            getValues={getValues}
                                          />
                                        )}
                                        {/* </Box> */}
                                      </Grid>
                                      {/* <Grid xs={3}></Grid> */}
                                    </Grid>
                                  </Grid>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      )}

                      {questionField.contentElem === FormContentElemEnum.QUESTION && (
                        <>
                          {index === linkedQuestions.find((elem) => elem === index) && (
                            <Box
                              sx={{
                                mb:
                                  fieldContentData[index + 1]?.contentElem === FormContentElemEnum.NEW_PAGE
                                    ? 0
                                    : fieldContentData[index + 1]?.contentElem === FormContentElemEnum.QUESTION
                                    ? 1.5
                                    : 2.5,
                              }}
                            >
                              <Grid container>
                                {/* <Grid xs={3}></Grid> */}
                                <Grid xs={12}>
                                  {/* <QuestionComponent
                                    fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                    fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                    mandatory={questionField.singleQuestion.mandatory}
                                    descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                    descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                    hyperlink={questionField.singleQuestion.hyperlink}
                                  /> */}
                                  {questionField.singleQuestion.fieldType === FieldEnum.YES_NO && (
                                    <SelectYesNo
                                      register={register}
                                      errors={errors}
                                      watch={watch}
                                      setValue={setValue}
                                      control={control}
                                      question={questionField.singleQuestion}
                                      setLinkedQuestion={(selectedIndexes: number[], deselectIndexes: number[]) => {
                                        function checkExtra(originDeselectIndexes: number[], originalArr: number[]) {
                                          const newDeselectIndexes = originDeselectIndexes;

                                          function loopDeselectIndex(i: number) {
                                            const singleChoices = fieldContentData[i].singleQuestion.singleChoiceQuestions;
                                            const yesLinkedQuestions = fieldContentData[i].singleQuestion.yesNoQuestions.yes.linkedQuestion;
                                            const noLinkedQuestions = fieldContentData[i].singleQuestion.yesNoQuestions.no.linkedQuestion;

                                            for (const singleChoice of singleChoices) {
                                              const singleChoiceLinkedQuestions = singleChoice.linkedQuestion;
                                              for (const singleChoiceLinkedQuestion of singleChoiceLinkedQuestions) {
                                                newDeselectIndexes.push(parseInt(singleChoiceLinkedQuestion));
                                                loopDeselectIndex(parseInt(singleChoiceLinkedQuestion));
                                              }
                                            }

                                            for (const yesLinkedQuestion of yesLinkedQuestions) {
                                              newDeselectIndexes.push(parseInt(yesLinkedQuestion));
                                              loopDeselectIndex(parseInt(yesLinkedQuestion));
                                            }

                                            for (const noLinkedQuestion of noLinkedQuestions) {
                                              newDeselectIndexes.push(parseInt(noLinkedQuestion));
                                              loopDeselectIndex(parseInt(noLinkedQuestion));
                                            }
                                          }

                                          for (const deselectIndex of deselectIndexes) {
                                            loopDeselectIndex(deselectIndex);
                                            resetField(`${fieldContentData[deselectIndex].singleQuestion.id}`);
                                          }

                                          const updatedArr = originalArr.filter((linkedQuestion) => !deselectIndexes.includes(linkedQuestion));
                                          return updatedArr;
                                        }

                                        let modifiedArr: number[] = [...linkedQuestions];
                                        // console.log("Before REMOVING =", modifiedArr);

                                        const afterDeselectionArr = checkExtra(deselectIndexes, modifiedArr);
                                        modifiedArr = afterDeselectionArr;
                                        // console.log("After REMOVING =", afterDeselectionArr);

                                        const checkSelect = linkedQuestions.filter((linkedQuestion) => selectedIndexes.includes(linkedQuestion));
                                        if (checkSelect.length === 0) {
                                          for (const selectedIndex of selectedIndexes) {
                                            modifiedArr.push(selectedIndex);
                                          }
                                        }
                                        // console.log("After SELECTING =", modifiedArr);
                                        setLinkedQuestions(modifiedArr);
                                      }}
                                    />
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.SHORT_ANSWER && (
                                    <>
                                      {questionField.singleQuestion.mandatory === true ? (
                                        <FormControl fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                          <QuestionComponent
                                            fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                            fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                            mandatory={questionField.singleQuestion.mandatory}
                                            descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                            descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                            hyperlink={questionField.singleQuestion.hyperlink}
                                            error={errors[questionField.singleQuestion.id] && true}
                                          />
                                          <Controller
                                            name={`${questionField.singleQuestion.id}` as const}
                                            control={control}
                                            rules={{ required: t("forms.required") }}
                                            defaultValue=""
                                            render={({ field }) => (
                                              <RoundTextField
                                                {...field}
                                                fullWidth
                                                size="small"
                                                type="text"
                                                error={errors[questionField.singleQuestion.id] && true}
                                                helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      ) : (
                                        <FormControl fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                          <QuestionComponent
                                            fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                            fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                            mandatory={questionField.singleQuestion.mandatory}
                                            descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                            descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                            hyperlink={questionField.singleQuestion.hyperlink}
                                            error={errors[questionField.singleQuestion.id] && true}
                                          />
                                          <Controller
                                            name={`${questionField.singleQuestion.id}` as const}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                              <RoundTextField
                                                {...field}
                                                fullWidth
                                                size="small"
                                                type="text"
                                                error={errors[questionField.singleQuestion.id] && true}
                                                helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      )}

                                      <>
                                        {questionField.singleQuestion.numberOfMultipleAns !== undefined &&
                                          getMultipleAnswerNumber(questionField.singleQuestion.numberOfMultipleAns).map((value) => {
                                            return (
                                              <Controller
                                                key={value}
                                                name={`${questionField.singleQuestion.id}-${value}` as const}
                                                control={control}
                                                rules={{ required: t("forms.required") }}
                                                defaultValue=""
                                                render={({ field }) => (
                                                  <RoundTextField
                                                    sx={{ mt: 1 }}
                                                    {...field}
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    error={errors[questionField.singleQuestion.id] && true}
                                                    helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                                  />
                                                )}
                                              />
                                            );
                                          })}
                                      </>

                                      {questionField.singleQuestion.multipleAnswers !== undefined && questionField.singleQuestion.multipleAnswers && (
                                        <div className="submissionAllowMultipleAnsContainer">
                                          <div className="submissionAllowMultipleAnsBtn">
                                            <AddCircleOutlineRoundedIcon
                                              fontSize="small"
                                              onClick={() => {
                                                const newData = [...fieldContentData];
                                                let newNumberOfMultipleAns = newData[index].singleQuestion.numberOfMultipleAns;
                                                if (newNumberOfMultipleAns !== undefined) {
                                                  newNumberOfMultipleAns += 1;
                                                } else {
                                                  newNumberOfMultipleAns = 1;
                                                }

                                                newData[index].singleQuestion.numberOfMultipleAns = newNumberOfMultipleAns;

                                                setFieldContent(newData);
                                              }}
                                            />
                                          </div>
                                          <div className="submissionAllowMultipleAnsText">新增答案</div>
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.PARAGRAPH && (
                                    <>
                                      {questionField.singleQuestion.mandatory === true ? (
                                        <FormControl size="small" fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                          <QuestionComponent
                                            fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                            fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                            mandatory={questionField.singleQuestion.mandatory}
                                            descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                            descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                            hyperlink={questionField.singleQuestion.hyperlink}
                                            error={errors[questionField.singleQuestion.id] && true}
                                          />
                                          <Controller
                                            name={`${questionField.singleQuestion.id}` as const}
                                            control={control}
                                            rules={{ required: t("forms.required") }}
                                            defaultValue=""
                                            render={({ field }) => (
                                              <RoundTextArea
                                                {...field}
                                                fullWidth
                                                size="small"
                                                type="text"
                                                placeholder=""
                                                InputProps={{
                                                  inputComponent: TextareaAutosize,
                                                }}
                                                error={errors[questionField.singleQuestion.id] && true}
                                                helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      ) : (
                                        <FormControl size="small" fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                          <QuestionComponent
                                            fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                            fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                            mandatory={questionField.singleQuestion.mandatory}
                                            descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                            descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                            hyperlink={questionField.singleQuestion.hyperlink}
                                            error={errors[questionField.singleQuestion.id] && true}
                                          />
                                          <Controller
                                            name={`${questionField.singleQuestion.id}` as const}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                              <RoundTextArea
                                                {...field}
                                                fullWidth
                                                size="small"
                                                type="text"
                                                placeholder=""
                                                InputProps={{
                                                  inputComponent: TextareaAutosize,
                                                }}
                                                error={errors[questionField.singleQuestion.id] && true}
                                                helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      )}
                                    </>
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.VALUE && (
                                    <>
                                      {questionField.singleQuestion.mandatory === true ? (
                                        <FormControl size="small" fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                          <QuestionComponent
                                            fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                            fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                            mandatory={questionField.singleQuestion.mandatory}
                                            descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                            descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                            hyperlink={questionField.singleQuestion.hyperlink}
                                            error={errors[questionField.singleQuestion.id] && true}
                                          />
                                          <Controller
                                            name={`${questionField.singleQuestion.id}` as const}
                                            control={control}
                                            rules={{ required: t("forms.required") }}
                                            defaultValue={"" as unknown as number}
                                            render={({ field }) => (
                                              <RoundTextField
                                                {...field}
                                                fullWidth
                                                size="small"
                                                type="number"
                                                onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                  if (parseInt(e.target.value) >= 0) {
                                                    field.onChange(parseInt(e.target.value));
                                                  }
                                                }}
                                                error={errors[questionField.singleQuestion.id] && true}
                                                helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      ) : (
                                        <FormControl size="small" fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                          <QuestionComponent
                                            fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                            fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                            mandatory={questionField.singleQuestion.mandatory}
                                            descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                            descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                            hyperlink={questionField.singleQuestion.hyperlink}
                                            error={errors[questionField.singleQuestion.id] && true}
                                          />
                                          <Controller
                                            name={`${questionField.singleQuestion.id}` as const}
                                            control={control}
                                            defaultValue={"" as unknown as number}
                                            render={({ field }) => (
                                              <RoundTextField
                                                {...field}
                                                fullWidth
                                                size="small"
                                                type="number"
                                                onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                  if (parseInt(e.target.value) >= 0) {
                                                    field.onChange(parseInt(e.target.value));
                                                  }
                                                }}
                                                error={errors[questionField.singleQuestion.id] && true}
                                                helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      )}
                                    </>
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.IMAGE_UPLOAD && (
                                    <UploadImage
                                      question={questionField.singleQuestion}
                                      submissionAttachments={submissionAttachments}
                                      deselectFiles={(id: number) => {
                                        const deletedFiles = disableFiles;
                                        deletedFiles.push(id);
                                        setDisableFiles(deletedFiles);
                                      }}
                                      register={register}
                                      errors={errors}
                                      watch={watch}
                                      clearErrors={clearErrors}
                                      setValue={setValue}
                                      getValues={getValues}
                                      control={control}
                                      trigger={trigger}
                                    />
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.FILE_UPLOAD && (
                                    <UploadFile
                                      question={questionField.singleQuestion}
                                      submissionAttachments={submissionAttachments}
                                      deselectFiles={(id: number) => {
                                        const deletedFiles = disableFiles;
                                        deletedFiles.push(id);
                                        setDisableFiles(deletedFiles);
                                      }}
                                      register={register}
                                      errors={errors}
                                      watch={watch}
                                      setValue={setValue}
                                      getValues={getValues}
                                      control={control}
                                      trigger={trigger}
                                    />
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.SINGLE_CHOICE && (
                                    <SingleChoice
                                      register={register}
                                      errors={errors}
                                      watch={watch}
                                      setValue={setValue}
                                      control={control}
                                      question={questionField.singleQuestion}
                                      setLinkedQuestion={(selectedIndexes: number[], deselectIndexes: number[]) => {
                                        function checkExtra(originDeselectIndexes: number[], originalArr: number[]) {
                                          const newDeselectIndexes = originDeselectIndexes;

                                          function loopDeselectIndex(i: number) {
                                            const singleChoices = fieldContentData[i].singleQuestion.singleChoiceQuestions;
                                            const yesLinkedQuestions = fieldContentData[i].singleQuestion.yesNoQuestions.yes.linkedQuestion;
                                            const noLinkedQuestions = fieldContentData[i].singleQuestion.yesNoQuestions.no.linkedQuestion;

                                            for (const singleChoice of singleChoices) {
                                              const singleChoiceLinkedQuestions = singleChoice.linkedQuestion;
                                              for (const singleChoiceLinkedQuestion of singleChoiceLinkedQuestions) {
                                                newDeselectIndexes.push(parseInt(singleChoiceLinkedQuestion));
                                                loopDeselectIndex(parseInt(singleChoiceLinkedQuestion));
                                              }
                                            }

                                            for (const yesLinkedQuestion of yesLinkedQuestions) {
                                              newDeselectIndexes.push(parseInt(yesLinkedQuestion));
                                              loopDeselectIndex(parseInt(yesLinkedQuestion));
                                            }

                                            for (const noLinkedQuestion of noLinkedQuestions) {
                                              newDeselectIndexes.push(parseInt(noLinkedQuestion));
                                              loopDeselectIndex(parseInt(noLinkedQuestion));
                                            }
                                          }

                                          for (const deselectIndex of deselectIndexes) {
                                            loopDeselectIndex(deselectIndex);
                                            resetField(`${fieldContentData[deselectIndex].singleQuestion.id}`);
                                          }

                                          const updatedArr = originalArr.filter((linkedQuestion) => !deselectIndexes.includes(linkedQuestion));
                                          return updatedArr;
                                        }

                                        let modifiedArr: number[] = [...linkedQuestions];
                                        // console.log("Before REMOVING =", modifiedArr);

                                        const afterDeselectionArr = checkExtra(deselectIndexes, modifiedArr);
                                        // console.log("After REMOVING =", afterDeselectionArr);
                                        modifiedArr = afterDeselectionArr;

                                        const checkSelect = linkedQuestions.filter((linkedQuestion) => selectedIndexes.includes(linkedQuestion));
                                        if (checkSelect.length === 0) {
                                          for (const selectedIndex of selectedIndexes) {
                                            modifiedArr.push(selectedIndex);
                                          }
                                        }
                                        // console.log("After SELECTING =", modifiedArr);

                                        setLinkedQuestions(modifiedArr);
                                      }}
                                    />
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.MULTIPLE_CHOICES && (
                                    <MultipleCheckBox
                                      question={questionField.singleQuestion}
                                      errors={errors}
                                      setValue={setValue}
                                      getValues={getValues}
                                      control={control}
                                      trigger={trigger}
                                    />
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.DROPDOWN && (
                                    <Dropdown
                                      register={register}
                                      errors={errors}
                                      control={control}
                                      question={questionField.singleQuestion}
                                      dropdownQuestions={questionField.singleQuestion.dropdownQuestions.type}
                                      dropdownQuestionsCustom={questionField.singleQuestion.dropdownQuestions.custom}
                                    />
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.DATEPICKER && (
                                    <>
                                      {questionField.singleQuestion.mandatory === true ? (
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                          <FormControl size="small" fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                            <QuestionComponent
                                              fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                              fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                              mandatory={questionField.singleQuestion.mandatory}
                                              descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                              descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                              hyperlink={questionField.singleQuestion.hyperlink}
                                              error={errors[questionField.singleQuestion.id] && true}
                                            />
                                            <Controller
                                              name={`${questionField.singleQuestion.id}` as const}
                                              control={control}
                                              rules={{ required: t("forms.required") }}
                                              defaultValue={"" as unknown as Date}
                                              render={({ field }) => (
                                                <DesktopDatePicker
                                                  {...field}
                                                  inputFormat="DD/MM/yyyy"
                                                  onChange={(date) => {
                                                    field.onChange(moment(date).toDate());
                                                  }}
                                                  renderInput={(formParams) => (
                                                    <RoundTextField
                                                      {...formParams}
                                                      size="small"
                                                      fullWidth
                                                      type="date"
                                                      error={errors[questionField.singleQuestion.id] && true}
                                                      helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                                    />
                                                  )}
                                                />
                                              )}
                                            />
                                          </FormControl>
                                        </LocalizationProvider>
                                      ) : (
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                          <FormControl size="small" fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                            <QuestionComponent
                                              fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                              fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                              mandatory={questionField.singleQuestion.mandatory}
                                              descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                              descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                              hyperlink={questionField.singleQuestion.hyperlink}
                                              error={errors[questionField.singleQuestion.id] && true}
                                            />
                                            <Controller
                                              name={`${questionField.singleQuestion.id}` as const}
                                              control={control}
                                              render={({ field }) => (
                                                <DesktopDatePicker
                                                  {...field}
                                                  inputFormat="DD/MM/yyyy"
                                                  onChange={(date) => {
                                                    field.onChange(moment(date).toDate());
                                                  }}
                                                  renderInput={(formParams) => (
                                                    <RoundTextField
                                                      {...formParams}
                                                      size="small"
                                                      fullWidth
                                                      type="date"
                                                      error={errors[questionField.singleQuestion.id] && true}
                                                      helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                                    />
                                                  )}
                                                />
                                              )}
                                            />
                                          </FormControl>
                                        </LocalizationProvider>
                                      )}
                                    </>
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.EMAIL && (
                                    <>
                                      {questionField.singleQuestion.mandatory === true ? (
                                        <FormControl fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                          <QuestionComponent
                                            fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                            fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                            mandatory={questionField.singleQuestion.mandatory}
                                            descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                            descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                            hyperlink={questionField.singleQuestion.hyperlink}
                                            error={errors[questionField.singleQuestion.id] && true}
                                          />
                                          <Controller
                                            name={`${questionField.singleQuestion.id}` as const}
                                            control={control}
                                            rules={{ required: t("forms.required") }}
                                            defaultValue=""
                                            render={({ field }) => (
                                              <RoundTextField
                                                {...field}
                                                fullWidth
                                                size="small"
                                                type="email"
                                                error={errors[questionField.singleQuestion.id] && true}
                                                helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      ) : (
                                        <FormControl fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                          <QuestionComponent
                                            fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                            fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                            mandatory={questionField.singleQuestion.mandatory}
                                            descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                            descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                            hyperlink={questionField.singleQuestion.hyperlink}
                                            error={errors[questionField.singleQuestion.id] && true}
                                          />
                                          <Controller
                                            name={`${questionField.singleQuestion.id}` as const}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                              <RoundTextField
                                                {...field}
                                                fullWidth
                                                size="small"
                                                type="email"
                                                error={errors[questionField.singleQuestion.id] && true}
                                                helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      )}
                                    </>
                                  )}

                                  {/* {questionField.singleQuestion.fieldType === FieldEnum.PHONE_NUMBER && (
                                    <PhoneNumber
                                      register={register}
                                      errors={errors}
                                      watch={watch}
                                      setValue={setValue}
                                      getValues={getValues}
                                      control={control}
                                      question={questionField.singleQuestion}
                                    />
                                  )} */}

                                  {questionField.singleQuestion.fieldType === FieldEnum.PHONE_NUMBER && (
                                    <PhoneNumberV2
                                      register={register}
                                      errors={errors}
                                      watch={watch}
                                      setValue={setValue}
                                      getValues={getValues}
                                      control={control}
                                      question={questionField.singleQuestion}
                                    />
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.APPLICATION_NUMBER && (
                                    <>
                                      {questionField.singleQuestion.mandatory === true ? (
                                        <FormControl fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                          <QuestionComponent
                                            fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                            fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                            mandatory={questionField.singleQuestion.mandatory}
                                            descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                            descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                            hyperlink={questionField.singleQuestion.hyperlink}
                                            error={errors[questionField.singleQuestion.id] && true}
                                          />
                                          <Controller
                                            name={`${questionField.singleQuestion.id}` as const}
                                            control={control}
                                            rules={{ required: t("forms.required") }}
                                            defaultValue=""
                                            render={({ field }) => (
                                              <RoundTextField
                                                {...field}
                                                fullWidth
                                                size="small"
                                                type="text"
                                                error={errors[questionField.singleQuestion.id] && true}
                                                helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      ) : (
                                        <FormControl fullWidth error={errors[questionField.singleQuestion.id] && true}>
                                          <QuestionComponent
                                            fieldQuestionZhHk={questionField.singleQuestion.fieldQuestion.zhHK}
                                            fieldQuestionEnUs={questionField.singleQuestion.fieldQuestion.enUS}
                                            mandatory={questionField.singleQuestion.mandatory}
                                            descriptionZhHk={questionField.singleQuestion.description?.zhHK}
                                            descriptionEnUs={questionField.singleQuestion.description?.enUS}
                                            hyperlink={questionField.singleQuestion.hyperlink}
                                            error={errors[questionField.singleQuestion.id] && true}
                                          />
                                          <Controller
                                            name={`${questionField.singleQuestion.id}` as const}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                              <RoundTextField
                                                {...field}
                                                fullWidth
                                                size="small"
                                                type="text"
                                                error={errors[questionField.singleQuestion.id] && true}
                                                helperText={errors[questionField.singleQuestion.id] && (errors[questionField.singleQuestion.id]?.message as ReactNode)}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                      )}
                                    </>
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.CHECKBOX && (
                                    <SingleCheckBox
                                      register={register}
                                      errors={errors}
                                      setValue={setValue}
                                      getValues={getValues}
                                      control={control}
                                      question={questionField.singleQuestion}
                                    />
                                  )}

                                  {questionField.singleQuestion.fieldType === FieldEnum.MULTIPLE_ANSWER_SET && (
                                    <MultipleAnswerSetSubmission
                                      question={questionField.singleQuestion}
                                      answerSets={questionField.singleQuestion.multipleAnswerSet.answerSets}
                                      answers={questionField.singleQuestion.multipleAnswerSet.answers}
                                      register={register}
                                      errors={errors}
                                      setError={setError}
                                      clearErrors={clearErrors}
                                      watch={watch}
                                      setValue={setValue}
                                      getValues={getValues}
                                    />
                                  )}
                                </Grid>
                                {/* <Grid xs={3}></Grid> */}
                              </Grid>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  );
                })}
              </Grid>

              <Grid xs={12} sx={{ mb: 2.5, maxWidth: 1440, display: "flex", justifyContent: "center" }}>
                {steps.map((_, index) => (
                  <Box
                    key={_}
                    sx={{
                      width: activeStep === index ? 20 : 5,
                      height: 5,
                      borderRadius: activeStep === index ? 5 : "50%",
                      mx: 0.4,
                      bgcolor: "#888888",
                      transition: "width 0.3s, background-color 0.3s",
                      opacity: activeStep === index ? 1 : 0.5,
                      cursor: activeStep === index ? "default" : "pointer",
                    }}
                    onClick={handleStep(index)}
                  />
                ))}
              </Grid>

              <Grid xs={12} sx={{ mb: 2.5, maxWidth: 700 }}>
                <Grid container display="flex" justifyContent={activeStep === 0 ? "flex-end" : "space-between"} spacing={2}>
                  {/* <Grid xs={3} md={3}></Grid> */}
                  {activeStep !== 0 && steps.length > 1 && (
                    <>
                      <Grid xs={6}>
                        <Button variant="outlined" className="registerPageRegisterBtn" startIcon={<LongArrowLeftIcon />} onClick={handleStep(activeStep - 1)}>
                          {t("view.previousPage")}
                        </Button>
                      </Grid>
                      {/* <Grid xs={3} md={0.25}></Grid> */}
                    </>
                  )}
                  {activeStep < steps.length - 1 && (
                    <>
                      {/* <Grid xs={3} md={0.25}></Grid> */}
                      <Grid xs={6}>
                        <Button variant="outlined" className="registerPageRegisterBtn" endIcon={<LongArrowRightIcon />} onClick={handleStep(activeStep + 1)}>
                          {t("view.nextPage")}
                        </Button>
                      </Grid>
                    </>
                  )}
                  {activeStep === steps.length - 1 && (
                    <>
                      {/* <Grid xs={3} md={0.25}></Grid> */}
                      <Grid xs={6}>
                        <Button variant="outlined" className="submissionBtn" type="submit" disabled={isSubmitting}>
                          {t("view.submit")}
                        </Button>
                      </Grid>
                    </>
                  )}
                  {/* <Grid xs={3} md={3}></Grid> */}
                  {/* <Grid xs={3} md={3}></Grid>
                  <Grid xs={6} md={6}>
                    <Button variant="outlined" className="registerPageRegisterBtn" onClick={saveForm}>
                      {t("view.save")}
                    </Button>
                  </Grid>
                  <Grid xs={3} md={3}></Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </StyledEngineProvider>
      )}
    </>
  );
}

export default EditSubmissionForm;
