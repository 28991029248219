import queryString from "query-string";
import sha256 from "sha256";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import client, { instance } from "../../api/interceptors";
import { RootState, AppDispatch } from "../store";
import { adminVerifiedSuccess, studentAutoLoginFail, studentAutoLoginSuccess } from "./actions";
import { JWTPayload } from "./reducer";
import { verify } from "../../api/auth";

interface Token {
  schoolCode: string | null;
  token: string | null;
  timestamp: number | null;
}

function setToken(tokenDetails: Token): void {
  const { schoolCode, token, timestamp } = tokenDetails;
  if (schoolCode !== null && token !== null && timestamp !== null) {
    sessionStorage.setItem("lastSchool", schoolCode);
    sessionStorage.setItem(`token:${tokenDetails.schoolCode}`, token);
    sessionStorage.setItem(`token:${tokenDetails.schoolCode}:timestamp`, timestamp.toString());
  }
}

async function refreshToken() {
  const res = await client.post("refresh");
  const result = res.data;
  return result;
}

async function verifyToken() {
  const res = await client.post("verify");
  const result = res.data;
  return result.success;
}

async function getCsrfToken() {
  const res = await client.get("csrf");
  const result = res.data;
  return result;
}

function setInstance(tokenDetails: Token): void {
  const { schoolCode, token, timestamp } = tokenDetails;
  console.log("window.location.origin", window.location.origin);
  console.log("process.env.NODE_ENV", process.env.NODE_ENV);
  const hostname = window.location.origin;
  console.log("hostname", hostname);
  const baseURL = `${hostname}/school/${schoolCode}`;
  instance.defaults.baseURL = baseURL;
  instance.defaults.params = { token, timestamp };
}

function generateTokenDetail(schoolCode: string) {
  const timestamp = Math.round(Date.now() / 1000);
  const hash = sha256(`${schoolCode}${process.env.REACT_APP_SPC_AUTH_TOKEN_SECRET}${timestamp}`);
  const tokenDetails = {
    schoolCode,
    token: hash,
    timestamp,
  };
  console.log("generateTokenDetail tokenDetails", tokenDetails);
  setInstance(tokenDetails);
}

function setCsrfTokenHeader(csrfToken: string) {
  instance.defaults.headers.get["X-CSRF-Token"] = csrfToken;
  instance.defaults.headers.post["X-CSRF-Token"] = csrfToken;
  instance.defaults.headers.patch["X-CSRF-Token"] = csrfToken;
  instance.defaults.headers.put["X-CSRF-Token"] = csrfToken;
  instance.defaults.headers.delete["X-CSRF-Token"] = csrfToken;
}

// export function csrfProtection() {
//     return async (dispatch: AppDispatch, getState: () => RootState) => {
//         const csrfToken = await getCsrfToken();
//         console.log("csrfToken", csrfToken);
//     };
// }

export function schoolAuthenticate(schoolCode?: string) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const pathname = getState().router.location?.pathname;
    const search = getState().router.location?.search;
    if (process.env.NODE_ENV === "production") {
      if (pathname && search) {
        const { token, timestamp } = queryString.parse(search);
        if (schoolCode && typeof token === "string" && typeof timestamp === "string") {
          const tokenDetails = {
            schoolCode,
            token,
            timestamp: parseInt(timestamp),
          };
          console.log("schoolAuthenticate tokenDetails", tokenDetails);
          setInstance(tokenDetails);

          const csrfToken = await getCsrfToken();
          setCsrfTokenHeader(csrfToken.data);

          const verified = await verifyToken();
          if (verified) {
            const resfreshedToken = await refreshToken();
            const newTokenDetails = { schoolCode, token: resfreshedToken.token, timestamp: resfreshedToken.timestamp };
            setToken(newTokenDetails);
            setInstance(newTokenDetails);
            dispatch(adminVerifiedSuccess());
          } else {
            alert("Your session is invalid, please refresh your page");
          }
        } else {
          if (!schoolCode) {
            console.log("Assertion failed: schoolCode is missing or invalid");
          }
          if (typeof token !== "string") {
            console.log("Assertion failed: token is not a string");
          }
          if (typeof timestamp !== "string") {
            console.log("Assertion failed: timestamp is not a string");
          }
          alert("Your session is invalid, please refresh your page");
        }
      } else {
        alert("Your session is invalid, please refresh your page");
      }
    } else if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
      if (schoolCode) {
        generateTokenDetail(schoolCode);

        const csrfToken = await getCsrfToken();
        setCsrfTokenHeader(csrfToken.data);

        const verified = await verifyToken();
        if (verified) {
          const resfreshedToken = await refreshToken();
          const newTokenDetails = { schoolCode, token: resfreshedToken.token, timestamp: resfreshedToken.timestamp };
          setToken(newTokenDetails);
          setInstance(newTokenDetails);
          dispatch(adminVerifiedSuccess());
        } else {
          alert("Your session is invalid, please refresh your page");
        }
      } else {
        alert("Your session is invalid, please refresh your page");
      }
    }
  };
}

export function studentAuthenticate(schoolCode?: string) {
  return async (dispatch: AppDispatch) => {
    try {
      if (schoolCode) {
        generateTokenDetail(schoolCode);

        const csrfToken = await getCsrfToken();
        setCsrfTokenHeader(csrfToken.data);

        const verified = await verifyToken();
        if (verified) {
          const resfreshedToken = await refreshToken();
          const newTokenDetails = { schoolCode, token: resfreshedToken.token, timestamp: resfreshedToken.timestamp };
          setToken(newTokenDetails);
          setInstance(newTokenDetails);
        } else {
          alert("Your session is invalid, please refresh your page");
        }

        const token = localStorage.getItem("token");
        if (token) {
          const payload: JWTPayload = jwt_decode(token);
          const user = await verify(payload);
          if (user.success) {
            dispatch(studentAutoLoginSuccess(user.data.id));
          } else {
            dispatch(studentAutoLoginFail());
          }
        } else {
          dispatch(studentAutoLoginFail());
        }
      } else {
        alert("Your session is invalid, please refresh your page");
      }
    } catch (error) {
      console.error(error);
      alert("Your session is invalid, please refresh your page");
    }
  };
}
