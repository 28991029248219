import { SelectedRowObj, UpdateRemarkObj } from "../components/applicationForm/view/ViewApplicationFormDashboard";
import { submissionFormData } from "../redux/applications/thunk";
import client, { buildLink } from "./interceptors";

export async function findSubmissionsByApplicationStageId(listId: number, stageId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission`);
  const result = res.data;
  return result;
}

export async function findSubmissionsAndUserByApplicationStageId(listId: number, stageId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission/users`);
  const result = res.data;
  return result;
}

export async function findSubmissionByStageIdAndUserId(listId: number, stageId: number, userId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission/user/${userId}`);
  const result = res.data;
  return result;
}

export async function findSubmissionDetailsByStageIdAndUserId(listId: number, stageId: number, userId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission/user/${userId}/details`);
  const result = res.data;
  return result;
}

export async function updateSubmissionStatusBySubmissionId(listId: number, stageId: number, data: SelectedRowObj) {
  const res = await client.put(`applicationList/${listId}/applicationStage/${stageId}/submission`, data);
  const result = res.data;
  return result;
}

export async function updateRemarksBySubmissionId(listId: number, stageId: number, data: UpdateRemarkObj) {
  const res = await client.put(`applicationList/${listId}/applicationStage/${stageId}/submission/remarks`, data);
  const result = res.data;
  return result;
}

export async function createSubmission(listId: number, stageId: number, data: submissionFormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/submission`, data);
  const result = res.data;
  return result;
}

export async function saveSubmission(listId: number, stageId: number, data: submissionFormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/submission/save`, data);
  const result = res.data;
  return result;
}

export async function createSubmissionAttachment(listId: number, stageId: number, submissionId: number, data: FormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}/attachments`, data);
  const result = res.data;
  return result;
}

export async function findSubmissionById(listId: number, stageId: number, submissionId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}`);
  const result = res.data;
  return result;
}

export async function findSubmissionAttachmentsById(listId: number, stageId: number, submissionId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}/attachments`);
  const result = res.data;
  return result;
}

export async function editSubmissionById(listId: number, stageId: number, submissionId: number, data: any) {
  const res = await client.put(`applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}`, data);
  const result = res.data;
  return result;
}

export async function downloadSubmissionFiles(listId: number, stageId: number, submissionId: number, fileName: string) {
  window.open(buildLink(`/applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}/download/file/${fileName}`));
}

export function generateSubmissionExcel(listId: number, stageId: number, fromdate: number, todate: number) {
  window.open(buildLink(`/applicationList/${listId}/applicationStage/${stageId}/excel/fromdate/${fromdate}/todate/${todate}`));
}

export async function uploadSubmissionExcel(listId: number, stageId: number, file: File) {
  const res = await client.post(`/applicationList/${listId}/applicationStage/${stageId}/excel`, { file });
  const result = res.data;
  return result;
}

export async function generateTemplateExcelForSubmissionStatus(listId: number, stageId: number) {
  window.open(buildLink(`/applicationList/${listId}/applicationStage/${stageId}/submission/excel/template`));
}

export async function generateCurrentExcelForSubmissionStatus(listId: number, stageId: number) {
  window.open(buildLink(`/applicationList/${listId}/applicationStage/${stageId}/submission/excel/current`));
}

export async function importApplicationStatusExcel(listId: number, stageId: number, data: FormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/submission/excel`, data);
  const result = res.data;
  return result;
}
