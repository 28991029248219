import "./RegisterPage.css";
import React, { ReactNode, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { push } from "redux-first-history";
import { useParams } from "react-router";
import PhoneInput, { CountryData } from "react-phone-input-2";
// import "react-phone-input-2/lib/material.css";
import { Alert, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Link, MenuItem, Snackbar } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import localization from "../utils/countryCode.json";
import { login, register } from "../../api/auth";
import ctfLogo from "../../images/CTF-logo.png";
import vpukLogo from "../../images/vpuk.png";
import soarFootballLogo from "../../images/soarfootballLogo.jpg";
import { DocumentTypeEnum } from "../../models/documentTypeEnum";
import { ErrorEnum } from "../../models/errorEnum";
import { studentLoginSuccess } from "../../redux/auth/actions";
import { RootState } from "../../redux/store";
import { CustomInputLabel, RoundSelect, RoundTextField, State } from "../utils/CustomStyle";
import SwitchLanguageButton from "./SwitchLanguageButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useWindowDimensions from "../applicationForm/interview/useWindowDimensions";
import "react-phone-input-2/lib/style.css";
import QuestionComponent from "../submission/QuestionComponent";

export interface RegisterFormValues {
  lastNameEnUs: string;
  firstNameEnUs: string;
  lastNameZhHk: string;
  firstNameZhHk: string;
  email: string;
  personalDocumentType: string;
  personalDocumentId: string;
  countryCode: string;
  phoneNumber: string;
  declaration: boolean;
}

function RegisterPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [haveRegister, setHaveRegister] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const isStudentAuthenticated = useAppSelector((state: RootState) => state.auth.isStudentAuthenticated);
  const lastSchool = sessionStorage.getItem("lastSchool");
  const isLoading = useAppSelector((state: RootState) => state.auth.isLoading);
  const user = useAppSelector((state: RootState) => state.auth.user);
  const param = useParams();

  useEffect(() => {
    if (!isLoading) {
      if (isStudentAuthenticated && user) {
        dispatch(push("list"));
      }
    }
  }, [dispatch, isLoading, isStudentAuthenticated, user]);

  const defaultValues: RegisterFormValues = {
    lastNameEnUs: "",
    firstNameEnUs: "",
    lastNameZhHk: "",
    firstNameZhHk: "",
    email: "",
    personalDocumentId: "",
    personalDocumentType: "",
    countryCode: "",
    phoneNumber: "",
    declaration: false,
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormValues>({
    defaultValues,
  });

  const [errorMsg, setErrorMsg] = useState("");
  const [state, setState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ open: false, vertical: "top", horizontal: "right" });
  };

  const onSubmit: SubmitHandler<RegisterFormValues> = async (data) => {
    if (lastSchool !== null) {
      const result = await register(data);
      if (result.success) {
        setPassword(result.password);
        setHaveRegister(true);
        setEmail(result.data.user.email);
      } else if (!result.success) {
        if (result.error.userMessage === ErrorEnum.USER_DUPLICATED) {
          setErrorMsg("User Duplicated");
        }
        setState({ open: true, vertical: "top", horizontal: "right" });
      }
    }
  };

  async function onClickLoginPage() {
    dispatch(push("login"));
  }

  async function onRedirectLoginPage() {
    const data = {
      email,
      password,
    };

    const result = await login(data);

    if (result.success) {
      dispatch(studentLoginSuccess(result.data, result.token));
      dispatch(push("list"));
    } else if (result.error.userMessage === ErrorEnum.USER_NOT_FOUND) {
      setErrorMsg("User Not Found");
    } else if (result.error.userMessage === ErrorEnum.USER_VALIDATION_ERROR) {
      setErrorMsg("Incorrect Username / Password");
    } else {
      dispatch(push("login"));
    }
  }

  useEffect(() => {
    if (isStudentAuthenticated) {
      dispatch(push(`/cms/school/${lastSchool}/application/list`));
    }
  }, [dispatch, isStudentAuthenticated, lastSchool]);

  const { width, height } = useWindowDimensions();
  const isMD = width < 900;
  console.log("lastSchool", lastSchool);
  return (
    <Grid sx={{ m: 3 }}>
      {isLoading && isStudentAuthenticated && <>Loading...</>}

      {!isLoading && !isStudentAuthenticated && (
        <>
          <Grid container display="flex" justifyContent="flex-end" alignItems="center" sx={{ m: 1 }}>
            <SwitchLanguageButton />
          </Grid>
          {lastSchool === "vpp" && (
            <Grid container display="flex" justifyContent="center" sx={{ m: 5 }}>
              <img src={ctfLogo} alt="School Logo" width={300} height={86} />
            </Grid>
          )}
          {lastSchool === "vpuk" && (
            <Grid container display="flex" justifyContent="center" sx={{ m: 5 }}>
              <img src={vpukLogo} alt="School Logo" width={300} height={86} />
            </Grid>
          )}
          {lastSchool === "soarfootball" && (
            <Grid container display="flex" justifyContent="center" sx={{ m: 5 }}>
              <img src={soarFootballLogo} alt="School Logo" width={300} height={86} />
            </Grid>
          )}
          {!haveRegister && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <div className="registerPageTitle">{t(lastSchool !== "soarfootball" ? "register.childInformation" : "register.childInformation_Soarfootball")}</div>
                </Grid>
                <Grid className="compulsoryDesc" xs={12}>
                  <span>{t("register.compulsory")}</span>
                </Grid>
                {!isMD && <Grid xs={0} md={4}></Grid>}
                <Grid xs={12} md={2}>
                  <Controller
                    name="lastNameEnUs"
                    control={control}
                    rules={{
                      required: `${t("register.lastNameEnUsIsRequired")}`,
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: t("forms.onlyAllowEnglish"),
                      },
                    }}
                    render={({ field }) => (
                      <RoundTextField
                        {...field}
                        fullWidth
                        size="small"
                        error={errors.lastNameEnUs && true}
                        id="lastNameEnUs"
                        label={t("register.lastNameEnUs")}
                        variant="outlined"
                        required
                        helperText={errors.lastNameEnUs && `${errors.lastNameEnUs.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={2}>
                  <Controller
                    name="firstNameEnUs"
                    control={control}
                    rules={{
                      required: `${t("register.firstNameEnUsIsRequired")}`,
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: t("forms.onlyAllowEnglish"),
                      },
                    }}
                    render={({ field }) => (
                      <RoundTextField
                        {...field}
                        fullWidth
                        size="small"
                        error={errors.firstNameEnUs && true}
                        id="firstNameEnUs"
                        label={t("register.firstNameEnUs")}
                        variant="outlined"
                        required
                        helperText={errors.firstNameEnUs && `${errors.firstNameEnUs.message}`}
                      />
                    )}
                  />
                </Grid>
                {!isMD && <Grid xs={0} md={4}></Grid>}

                {!isMD && <Grid xs={0} md={4}></Grid>}
                <Grid xs={12} md={2}>
                  <Controller
                    name="lastNameZhHk"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[\u4e00-\u9fff\s]+$/,
                        message: t("forms.onlyAllowChinese"),
                      },
                    }}
                    render={({ field }) => (
                      <RoundTextField
                        {...field}
                        fullWidth
                        size="small"
                        error={errors.lastNameZhHk && true}
                        id="lastNameZhHk"
                        label={t("register.lastNameZhHk")}
                        variant="outlined"
                        helperText={errors.lastNameZhHk && `${errors.lastNameZhHk.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={2}>
                  <Controller
                    name="firstNameZhHk"
                    control={control}
                    rules={{
                      pattern: {
                        value: /[\u4e00-\u9fff\s]+$/,
                        message: t("forms.onlyAllowChinese"),
                      },
                    }}
                    render={({ field }) => (
                      <RoundTextField
                        {...field}
                        fullWidth
                        size="small"
                        error={errors.firstNameZhHk && true}
                        id="firstNameZhHk"
                        label={t("register.firstNameZhHk")}
                        variant="outlined"
                        helperText={errors.firstNameZhHk && `${errors.firstNameZhHk.message}`}
                      />
                    )}
                  />
                </Grid>
                {!isMD && <Grid xs={0} md={4}></Grid>}

                {!isMD && <Grid xs={0} md={4}></Grid>}
                <Grid xs={12} md={4}>
                  <FormControl fullWidth size="small" required>
                    <CustomInputLabel id="personalDocumentType">{t("register.personalDocumentType")}</CustomInputLabel>
                    <Controller
                      name="personalDocumentType"
                      control={control}
                      rules={{ required: t("register.personalDocumentTypeIsRequired") }}
                      render={({ field }) => (
                        <RoundSelect
                          {...field}
                          fullWidth
                          error={errors.personalDocumentType && true}
                          id="personalDocumentType"
                          label={t("register.personalDocumentType")}
                          variant="outlined"
                        >
                          <MenuItem value={DocumentTypeEnum.BIRTH_CERTIFICATE}>{t("register.hkBirthCertificate")}</MenuItem>
                          <MenuItem value={DocumentTypeEnum.IDENTITY_CARD}>{t("register.hkIdentityCard")}</MenuItem>
                          <MenuItem value={DocumentTypeEnum.PASSPORT}>{t("register.passport")}</MenuItem>
                          <MenuItem value={DocumentTypeEnum.RE_ENTRY_PERMIT}>{t("register.reEntryPermit")}</MenuItem>
                          <MenuItem value={DocumentTypeEnum.CERTIFICATE_OF_IDENTITY}>{t("register.certificateOfIdentity")}</MenuItem>
                        </RoundSelect>
                      )}
                    />
                  </FormControl>
                </Grid>
                {!isMD && <Grid xs={0} md={4}></Grid>}

                {!isMD && <Grid xs={0} md={4}></Grid>}
                <Grid xs={12} md={4}>
                  <Controller
                    name="personalDocumentId"
                    control={control}
                    rules={{
                      required: `${t("register.personalDocumentIdIsRequired")}`,
                      pattern: {
                        value: /^[a-zA-Z0-9]*$/,
                        message: t("forms.noSpecialCharacterPattern"),
                      },
                    }}
                    render={({ field }) => (
                      <RoundTextField
                        {...field}
                        fullWidth
                        size="small"
                        error={errors.personalDocumentId && true}
                        id="personalDocumentId"
                        label={t("register.personalDocumentId")}
                        variant="outlined"
                        required
                        helperText={errors.personalDocumentId && `${errors.personalDocumentId.message}`}
                      />
                    )}
                  />
                </Grid>
                {!isMD && <Grid xs={0} md={4}></Grid>}

                {!isMD && <Grid xs={0} md={4}></Grid>}
                <Grid xs={12}>
                  <div className="registerPageTitle">{t(lastSchool !== "soarfootball" ? "register.parentInformation" : "register.parentInformation_Soarfootball")}</div>
                </Grid>
                {!isMD && <Grid xs={0} md={4}></Grid>}

                <Grid xs={12} md={4}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: t("register.emailIsRequired"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t("register.emailIsRequired"),
                      },
                    }}
                    render={({ field }) => (
                      <RoundTextField
                        {...field}
                        fullWidth
                        size="small"
                        required
                        error={errors.email && true}
                        id="email"
                        type="email"
                        label={t("register.email")}
                        variant="outlined"
                        helperText={errors.email && `${errors.email.message}`}
                      />
                    )}
                  />
                </Grid>
                {!isMD && <Grid xs={0} md={4}></Grid>}

                {!isMD && <Grid xs={0} md={4}></Grid>}

                {/* <Grid xs={1.5} md={1}>
                  <Controller
                    name="countryCode"
                    control={control}
                    rules={{ required: t("register.countryCodeIsRequired") }}
                    defaultValue=""
                    render={({ field }) => (
                      <RoundSelect {...field} style={{ height: "40px" }} error={errors.countryCode && true} required>
                        <MenuItem value="+852">+852</MenuItem>
                        <MenuItem value="+83">+83</MenuItem>
                        <MenuItem value="+86">+86</MenuItem>
                      </RoundSelect>
                    )}
                  />
                </Grid>
                <Grid xs={10.5} md={3}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{
                      required: t("register.phoneNumberIsRequired"),
                      pattern: {
                        value: /^[0-9]+$/,
                        message: t("register.phoneNumberIsRequired"),
                      },
                    }}
                    render={({ field }) => (
                      <RoundTextField
                        {...field}
                        fullWidth
                        size="small"
                        error={errors.phoneNumber && true}
                        id="phoneNumber"
                        required
                        type="phoneNumber"
                        label={t("register.phoneNumber")}
                        variant="outlined"
                        helperText={errors.phoneNumber && `${errors.phoneNumber.message}`}
                      />
                    )}
                  />
                </Grid> */}
                <Grid xs={12} md={4}>
                  <Controller
                    name="countryCode"
                    control={control}
                    rules={{ required: t("register.countryCodeIsRequired") }}
                    defaultValue=""
                    render={({ field: countryCodeField }) => (
                      <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                          required: t("register.phoneNumberIsRequired"),
                          pattern: {
                            value: /^[0-9]+$/,
                            message: t("register.phoneNumberIsRequired"),
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <PhoneInput
                              country="hk"
                              enableSearch
                              inputProps={{
                                name: "phoneNumber",
                                required: true,
                                autoFocus: true,
                              }}
                              placeholder={t("register.phoneNumber")}
                              inputStyle={{ width: "100%" }}
                              isValid={!errors.phoneNumber && !errors.countryCode}
                              defaultErrorMessage={errors.phoneNumber?.message}
                              // isValid={fieldState.error.phoneNumber}
                              localization={localization}
                              onChange={(value, country, e, formattedValue) => {
                                countryCodeField.onChange((country as CountryData).dialCode ? (country as CountryData).dialCode : "");
                                field.onChange(value.slice((country as CountryData).dialCode.length));
                              }}
                            />
                            <FormHelperText className="textRed">{errors.countryCode && (t("register.countryCodeIsRequired") as ReactNode)}</FormHelperText>
                            <FormHelperText className="textRed">{errors.phoneNumber && (t("register.phoneNumberIsRequired") as ReactNode)}</FormHelperText>
                          </>
                        )}
                      />
                    )}
                  />
                </Grid>
                {!isMD && <Grid xs={0} md={4}></Grid>}

                {!isMD && <Grid xs={0} md={4}></Grid>}

                <Grid xs={12} md={4}>
                  <QuestionComponent
                    fieldQuestionZhHk={t("register.declaration")}
                    fieldQuestionEnUs={t("register.declaration")}
                    mandatory
                    error={errors.declaration && true}
                    descriptionZhHk={undefined}
                    descriptionEnUs={undefined}
                    hyperlink={undefined}
                  />
                  <Controller
                    name="declaration"
                    control={control}
                    rules={{ required: `${t("register.declarationIsRequired")}` }}
                    render={({ field }) => (
                      <FormControlLabel
                        className="submissionNormalText"
                        checked={field.value}
                        control={
                          <Checkbox
                            {...field}
                            sx={{
                              color: "#888888",
                              "&.Mui-checked": {
                                color: "#888888",
                              },
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={
                          <div>
                            <span>{t("register.declarationDesc_1")}</span>
                            {lastSchool === "vpuk" && (
                              <>
                                <a
                                  href="https://www.victoria.edu.hk/victoria_cms/wp-content/uploads/2023/09/Personal_Information_Collection_Statement_UKApplication.pdf"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span className="link">
                                    {t("register.privacyPolicy")} {t("register.declarationDesc_2")} {t("register.personalInformationCollectionStatement")}
                                  </span>
                                </a>
                                <span>{t("register.declarationDesc_3_VPUK", { organizationName: "Victoria Educational Organisation" })}</span>{" "}
                              </>
                            )}
                            {lastSchool === "vpp" && (
                              <>
                                <a
                                  href={
                                    i18n.resolvedLanguage === "zhHK"
                                      ? "https://www.victoriaplaypark.com/zh-hant/privacy-policy/"
                                      : "https://www.victoriaplaypark.com/privacy-policy/"
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span className="link">{t("register.privacyPolicy")}</span>
                                </a>
                                <span>{t("register.declarationDesc_2")}</span>
                                <a
                                  href={
                                    i18n.resolvedLanguage === "zhHK"
                                      ? "https://www.victoriaplaypark.com/zh-hant/personal-information-collection-statement/"
                                      : "https://www.victoriaplaypark.com/personal-information-collection-statement/"
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span className="link">{t("register.personalInformationCollectionStatement")}</span>
                                </a>
                                <span>{t("register.declarationDesc_3_VPP", { organizationName: "Victoria Playpark" })}</span>{" "}
                              </>
                            )}
                            {lastSchool === "soarfootball" && (
                              <>
                                <span>{t("register.declarationSoarfootball")}</span>
                                <span className="link">{t("register.personalInformationCollectionStatement")}</span>
                              </>
                            )}
                          </div>
                        }
                      />
                    )}
                  />
                  <FormHelperText className="textRed">{errors.declaration && (t("register.declarationIsRequired") as ReactNode)}</FormHelperText>
                </Grid>
                {!isMD && <Grid xs={0} md={4}></Grid>}
                <Grid xs={3} md={4}></Grid>
                <Grid xs={6} md={4}>
                  <Button variant="outlined" type="submit" className="registerPageRegisterBtn">
                    {t("register.register")}
                  </Button>
                </Grid>
                <Grid xs={3} md={4}></Grid>

                <Grid xs={3} md={4}></Grid>
                <Grid xs={6} md={4} className="registerPageRegisterContainer">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link className="registerPageRegisterSpan" component="button" onClick={onClickLoginPage}>
                    {t("register.loginCurrentAccount")}
                  </Link>
                </Grid>
                <Grid xs={3}></Grid>
              </Grid>
            </form>
          )}

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
            <Alert severity="error">
              <strong>{errorMsg}</strong>
            </Alert>
          </Snackbar>

          {haveRegister && (
            <Grid container spacing={2}>
              <Grid xs={12}>
                <div className="registerPageApplicantId">
                  {t("register.password")}: {password}
                </div>
              </Grid>
              <Grid xs={12}>
                <div className="registerPageApplicantMsg">
                  {t("register.passwordIsSentTo")} <span className="registerPageApplicantEmail">{email}</span>
                </div>
                <div className="registerPageApplicantMsg">{t("register.registrationMsg")}</div>
              </Grid>

              <Grid xs={4}></Grid>
              <Grid xs={4}>
                <Button variant="outlined" className="registerPageRegisterBtn" onClick={onRedirectLoginPage}>
                  {t("register.continue")}
                </Button>
              </Grid>
              <Grid xs={4}></Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default RegisterPage;
